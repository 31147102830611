import React from 'react';

import AugmentedIcon from '../icons/augmented';
import {getARLink} from '../../lib/get-ar-link';

import './greeting.css';

/**
 * Button to launch AR mode
 */
export default function LaunchAR(props) {
  return (
    <a className="launch-ar-button" href={getARLink()}>
      <AugmentedIcon />
      <span>Check out in AR</span>
    </a>
  );
}
