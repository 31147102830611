/**
 * Checks if viewed in internet explorer 11
 */
export function isInternetExplorer11() {
  return !!window.MSInputMethodContext && !!document.documentMode;
}

/**
 * Check whether env supports webGl
 */
export function doesntSupportWebGl() {
  const canvas = document.createElement('canvas');
  const webGlContext =
    canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

  const doesSupportWebGl =
    webGlContext && webGlContext instanceof WebGLRenderingContext;

  return !doesSupportWebGl;
}

/**
 * Checks useragent whether device is mobile
 */
export function isMobile() {
  const regx = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return Boolean(regx.test(window.navigator.userAgent));
}

/**
 * Checks useragent whether device is iOS
 */
export function isIOS() {
  return /iPhone|iPod|iPad/.test(window.navigator.platform);
}
