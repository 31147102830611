import {Math as Math2} from 'three';

const DEFAULTS = {
  maxParticles: 100,
  minLifetime: 4000,
  maxLifetime: 6000,
  maxTimestep: 50
};

export class ParticleEmitter {
  constructor(options = {}) {
    const params = {...DEFAULTS, ...options};

    this.particleTypes = params.particleTypes;
    this.particleContext = params.particleContext;
    this.pool = params.particles ? params.particles.slice(0) : [];

    this.maxTimestep = params.maxTimestep;
    this.maxParticles = params.maxParticles;
    this.minLifetime = params.minLifetime;
    this.maxLifetime = params.maxLifetime;
    this.avgLifetime = (params.minLifetime + params.maxLifetime) / 2;

    // particles per millisecond
    this.maxSpawnRate = this.maxParticles / this.avgLifetime;
    this.setSpawnRate(params.spawnRate || this.maxSpawnRate);

    this.activeParticles = [];
    this.lastUpdateTime = 0;
  }

  setSpawnRate(rate) {
    this.spawnRate = Math2.clamp(rate, 0, this.maxSpawnRate);
  }

  update(t) {
    this.killExpiredParticles(t);

    const dt = Math.min(
      this.maxTimestep,
      this.lastUpdateTime ? t - this.lastUpdateTime : 0
    );
    const n = this.getNumberOfParticlesToSpawn(dt);

    if (n > 0) {
      this.spawn(n, t);
    }

    this.activeParticles.forEach(p => p.update(t, dt));

    this.lastUpdateTime = t;
  }

  spawn(numParticles, t) {
    // allocate neccessary number of particles
    if (this.particleTypes && this.pool.length < numParticles) {
      this.allocateParticles(numParticles - this.pool.length);
    }

    for (let i = 0; i < numParticles; i++) {
      const particle = this.pool.shift();

      // we might run out of available particles when there's no way
      // to allocate new ones (in any other case there will have been
      // enough created at this point)
      if (!particle) {
        break;
      }

      const lifetime =
        this.minLifetime +
        Math.random() * (this.maxLifetime - this.minLifetime);

      particle.doSpawn(t, lifetime);
      this.activeParticles.push(particle);
    }
  }

  killExpiredParticles(t) {
    for (let i = 0; i < this.activeParticles.length; i++) {
      const particle = this.activeParticles[i];
      const {spawntime, lifetime} = particle;

      if (t > spawntime + lifetime) {
        particle.die();

        this.activeParticles.splice(i, 1);
        this.pool.push(particle);
      }
    }
  }

  getNumberOfParticlesToSpawn(dt) {
    let numParticles = this.spawnRate * dt;

    if (numParticles > 1) {
      return Math.round(numParticles);
    }

    // value below 1 is treated as probability to spawn a new particle
    return Math.random() < numParticles ? 1 : 0;
  }

  allocateParticles(n = 1) {
    const numParticleTypes = this.particleTypes.length;
    n = numParticleTypes * Math.ceil(n / numParticleTypes);

    for (let i = 0; i < n; i++) {
      const ParticleConstructor = this.particleTypes[i % numParticleTypes];
      this.pool.push(new ParticleConstructor(this.particleContext));
    }
  }
}

export class Particle {
  constructor(context) {
    this.context = context;

    this.init();
  }

  init() {}
  spawn() {}
  update(t, dt) {}
  die() {}

  doSpawn(now, lifetime) {
    this.spawntime = now;
    this.lifetime = lifetime;

    this.spawn();
  }
}
