import React, {Component} from 'react';
import Main from '../../lib/three/Main';

import './scene.css';

export default class ThreeJsWrapper extends Component {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const {width, height} = this.props;

    this.scene = new Main(this.containerRef.current, {width, height});
    this.scene.start();
  }

  componentWillUnmount() {
    this.scene.stop();
    this.scene.dispose();
  }

  componentWillUpdate(nextProps) {
    const {width, height} = nextProps;

    if (width !== this.props.width || height !== this.props.height) {
      this.scene.setSize(width, height);
    }
  }

  render() {
    return (
      <canvas
        width={this.props.width}
        height={this.props.height}
        ref={this.containerRef}
      />
    );
  }
}
