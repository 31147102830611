import React from 'react';

import CancelIcon from '../icons/cancel';

import './info.css';

/**
 * Form where user can enter own custom message
 */
function InfoScreen(props) {
  const {closeInfoScreen} = props;

  return (
    <div className="info-screen-overlay">
      <div className="info-screen-container">
        <button className="info-screen-cancel" onClick={closeInfoScreen}>
          <CancelIcon />
        </button>
        <main className="info-screen-content">
          <h1 className="info-screen-content__title">New AR experience!</h1>
          <div className="info-screen-content__screenshot">
            <img
              src="assets/xmas-screenshot-01.png"
              alt="application screenshot"
            />
            <img
              src="assets/xmas-screenshot-02.png"
              alt="application screenshot"
            />
          </div>
          <div className="info-screen-content__text">
            <p>Curious to see this winter wonderland in Augmented Reality?</p>
            <p>
              Visit the website on your mobile phone, click the AR button, and
              place the globe wherever you like for some extra Christmas-y fun!
            </p>
          </div>
        </main>
      </div>
    </div>
  );
}

export default InfoScreen;
