import React from 'react';

import UbiLogo from '../icons/ubi-logo';
import LaunchARButton from './launch-ar-button';
import InfoScreenButton from '../info-screen/info-screen-button';

import {isMobile} from '../../lib/detect-envs';

import './greeting.css';

/**
 * The greeting card
 */
function GreetingCard(props) {
  const {showInfoScreen} = props;
  return (
    <div className="greating-card-dialog">
      <a className="logo" href="https://ubilabs.net/en">
        <UbiLogo />
      </a>
      <article className="greeting">
        <h1 className="message--default">
          Merry Christmas and a healthy New Year!
        </h1>
      </article>
      {isMobile() ? (
        <LaunchARButton />
      ) : (
        <InfoScreenButton onClick={showInfoScreen} />
      )}
    </div>
  );
}

export default GreetingCard;
