import {Particle, ParticleEmitter} from './particles';

import {
  InstancedBufferAttribute,
  InstancedBufferGeometry,
  Math as Math2,
  Mesh,
  MeshStandardMaterial,
  Quaternion,
  SphereBufferGeometry,
  Vector3
} from 'three';

const {randInt, randFloatSpread} = Math2;

const NUM_FLAKES = 600;

const v3 = new Vector3();

class Snowflake extends Particle {
  init() {
    this.rotation = new Quaternion();
    this.acceleration = new Vector3();
    this.velocity = new Vector3();
    this.position = new Vector3();
    this.intensity = 1;
  }

  spawn() {
    const rnd = randFloatSpread;

    this.setInitialPosition();

    // this.rotation.set(rnd(1), rnd(1), rnd(1), rnd(1)).normalize();
    this.acceleration.set(0, 0, 0);
    this.velocity.set(rnd(0.0001), rnd(0.0001), rnd(0.0001));
  }

  setInitialPosition() {
    let cloudIdx = randInt(0, this.context.clouds.length - 1);
    const cloud = this.context.clouds[cloudIdx];

    const {position, normal} = cloud.geometry.attributes;
    const randomIndex = randInt(0, position.count - 1);

    // pick a random cloud-vertex
    this.position.fromArray(position.array, randomIndex * 3);

    // offset along the reverse-normal so spawn-point is inside the cloud
    v3.fromArray(normal.array, randomIndex * 3)
      .normalize()
      .multiplyScalar(-0.1);

    this.position.add(v3);

    // convert from "cloud-space" to "earth-space"
    // (shared parent of clouds and snow)
    this.position.applyMatrix4(cloud.matrix);

    // offset a bit towards world-origin (prevents snowflakes appearing
    // above the cloud. Below is not so much a problem)
    v3.copy(this.position)
      .normalize()
      .multiplyScalar(-0.05);

    this.position.add(v3);
  }

  update(t, dt) {
    // acceleration with a fixed amount towards 0,0,0
    // (this is in fact an absolute dv-value adjusted
    // for the timestep so we can just add it to the velocity)
    this.acceleration
      .copy(this.position)
      .normalize()
      .multiplyScalar(-0.00000005 * dt);

    this.velocity.add(this.acceleration);
    v3.copy(this.velocity).multiplyScalar(dt * this.intensity);

    this.position.add(v3);
  }

  die() {
    this.position.set(0, 0, 0);
    this.rotation.set(0, 0, 0, 1);
  }
}

export default class Snow extends Mesh {
  constructor(clouds) {
    super(Snow.createGeometry(), Snow.createMaterial());

    this.frustumCulled = false;

    this.castShadow = true;
    this.receiveShadow = true;

    this.clouds = clouds;
    window.clouds = clouds;
    this.snowflakes = [];
    for (let i = 0; i < NUM_FLAKES; i++) {
      this.snowflakes.push(new Snowflake(this));
    }

    this.emitter = new ParticleEmitter({
      particles: this.snowflakes,
      maxParticles: NUM_FLAKES,
      minLifetime: 7500,
      maxLifetime: 8000
    });
  }

  update(t, intensity) {
    const offsetAttr = this.geometry.getAttribute('iOffset');
    const rotationAttr = this.geometry.getAttribute('iRotation');
    const offsets = offsetAttr.array;
    const rotations = rotationAttr.array;

    this.emitter.update(t);

    for (let i = 0; i < NUM_FLAKES; i++) {
      const f = this.snowflakes[i];

      f.position.toArray(offsets, i * 3);
      f.rotation.toArray(rotations, i * 4);
      f.intensity = intensity;
    }

    offsetAttr.needsUpdate = true;
    rotationAttr.needsUpdate = true;
  }

  setSpawnRate(magnitude) {
    this.emitter.setSpawnRate(magnitude * this.emitter.maxSpawnRate);
  }

  static createGeometry() {
    const baseGeometry = new SphereBufferGeometry(0.015, 5, 4);

    const offsets = new Float32Array(NUM_FLAKES * 3);
    const rotations = new Float32Array(NUM_FLAKES * 4);

    const offsetAttr = new InstancedBufferAttribute(offsets, 3, false, 1);
    const rotationAttr = new InstancedBufferAttribute(rotations, 4, false, 1);

    const geometry = new InstancedBufferGeometry();

    geometry.copy(baseGeometry);

    offsetAttr.setDynamic(true);
    rotationAttr.setDynamic(true);

    geometry.addAttribute('iOffset', offsetAttr);
    geometry.addAttribute('iRotation', rotationAttr);

    return geometry;
  }

  static createMaterial() {
    const material = new MeshStandardMaterial({
      color: 0xffffff,
      emissive: 0xffffff,
      emissiveIntensity: 0.4,
      flatShading: true
    });

    const replacedChunks = {
      begin_vertex: `vec3 transformed = iOffset + rotate(vec3(position), iRotation);`
    };

    material.onBeforeCompile = shader => {
      const vertexShader = Object.keys(replacedChunks).reduce(
        (vertexShader, chunkId) => {
          return vertexShader.replace(
            `#include <${chunkId}>`,
            replacedChunks[chunkId]
          );
        },
        shader.vertexShader
      );

      shader.vertexShader = `
        attribute vec3 iOffset;
        attribute vec4 iRotation;
        
        // apply a rotation-quaternion to the given vector 
        // (source: https://goo.gl/Cq3FU0)
        vec3 rotate(const vec3 v, const vec4 q) {
          vec3 t = 2.0 * cross(q.xyz, v);
          return v + q.w * t + cross(q.xyz, t);
        }
        
        ${vertexShader}
      `;
    };

    return material;
  }
}
