import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

import {Math as Math2} from 'three';

import CameraControlInput from './CameraControlInput';
import SnowControlInput from './SnowControlInput';
import ExploreModeControls from './ExploreModeControls';

import initOrbitControls from 'three-orbit-controls';
import GLTFLoader from 'three-gltf-loader';

import Smoke from './Smoke';
import Stars from './Stars';
import Snow from './Snow';

const {mapLinear} = Math2;

const OrbitControls = initOrbitControls(THREE);

export default class Main {
  constructor(canvas, options) {
    const {width, height} = options;
    this.renderloop = this.renderloop.bind(this);

    this.renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });

    this.renderer.toneMapping = THREE.LinearToneMapping;
    this.renderer.setSize(width, height);
    this.renderer.setClearColor(0x262c3e, 1);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.scene = new THREE.Scene();

    this.cameraControlInput = new CameraControlInput();
    this.snowControlInput = new SnowControlInput();

    const aspect = width / height;
    this.mainCamera = new THREE.PerspectiveCamera(45, aspect, 1, 30);
    this.debugCamera = new THREE.PerspectiveCamera(45, aspect, 0.1, 100);
    this.controls = this.initControls(this.debugCamera, document.body);

    this.group = new THREE.Group();
    this.exploreModeControls = new ExploreModeControls(
      this.group,
      this.mainCamera,
      canvas
    );
    this.group.add(new Stars());

    const dirLight = new THREE.DirectionalLight(0xffffff, 1);
    const ambLight = new THREE.AmbientLight(0xffffff, 0.5);
    const houseLight = new THREE.PointLight(0xffff00, 2, 1, 2);

    houseLight.castShadow = true;
    houseLight.shadow.camera.near = 0.01;
    houseLight.shadow.camera.far = 2;
    houseLight.shadow.bias = 1e-6;
    houseLight.shadow.mapSize.set(1024, 1024);

    dirLight.position.set(-8.5, -1, 7);
    dirLight.castShadow = true;

    Object.assign(dirLight.shadow.camera, {
      left: -5,
      right: 5,
      top: 5,
      bottom: -5,
      near: 6,
      far: 16
    });

    dirLight.shadow.bias = 1e-8;
    dirLight.shadow.radius = 2;
    dirLight.shadow.mapSize.set(1024, 1024);
    // this.scene.add(new THREE.CameraHelper(dirLight.shadow.camera));
    this.scene.add(this.group, dirLight, ambLight);

    window.THREE = THREE;
    window.s = this.scene;
    window.g = this.group;
    window.l = houseLight;
    window.c = this.mainCamera;
    window.debug = this.toggleDebugView.bind(this);

    this.mesh = new GLTFLoader().load('/assets/world.gltf', gltfData => {
      const {scene} = gltfData;

      this.group.add(...scene.getObjectByName('EXPORT').children);

      const cloudsGroup = this.group.getObjectByName('clouds');
      this.snow = new Snow(cloudsGroup.children);
      cloudsGroup.add(this.snow);

      this.smoke = new Smoke();
      this.smoke.scale.set(0.03, 0.03, 0.03);
      this.smoke.position.set(0, 0.04, 0);

      this.group.getObjectByName('smoke-emitter').add(this.smoke);
      this.group.getObjectByName('house').traverse(obj => {
        if (obj.material) {
          obj.material.side = THREE.DoubleSide;
        }
      });
      this.group.getObjectByName('forest').traverse(obj => {
        if (obj.material) {
          obj.material.side = THREE.DoubleSide;
        }
      });

      this.cameraMount = this.group.getObjectByName('Camera');
      this.cameraMount.rotateX(-Math.PI / 2 + 0.05);
      // detach the camera-mount from the main object
      this.scene.add(this.cameraMount);
      this.cameraMount.add(this.mainCamera);

      const pl = this.group.getObjectByName('pointlight');
      pl.add(houseLight);

      // enable shadows
      this.group.traverse(obj => {
        obj.receiveShadow = true;
        obj.castShadow = true;
      });

      this.toggleDebugView(false);
    });
  }

  toggleDebugView(enableDebugView) {
    this.isDebugViewEnabled = enableDebugView;
    this.controls.enabled = enableDebugView;

    if (enableDebugView) {
      if (!this.cameraHelper) {
        this.cameraHelper = new THREE.CameraHelper(this.mainCamera);
      }

      this.scene.add(this.cameraHelper);
      this.debugCamera.position.set(20, 5, 20);
    } else {
      this.mainCamera.position.set(0, 0, 0);
      this.mainCamera.rotation.set(0, 0, 0);

      this.scene.remove(this.cameraHelper);
    }
  }

  setSize(width, height) {
    this.renderer.setSize(width, height);

    this.mainCamera.aspect = width / height;
    this.mainCamera.updateProjectionMatrix();

    this.debugCamera.aspect = width / height;
    this.debugCamera.updateProjectionMatrix();
  }

  start() {
    this.rafId = requestAnimationFrame(this.renderloop);
  }

  stop() {
    cancelAnimationFrame(this.rafId);
  }

  dispose() {
    // TODO: cleanup all references and so on. For now, the hardcore way
    this.renderer.forceContextLoss();
  }

  renderloop(time) {
    this.rafId = requestAnimationFrame(this.renderloop);
    TWEEN.update();
    if (this.controls.enabled) {
      this.controls.update();
    }

    this.exploreModeControls.update();

    this.cameraControlInput.update();

    const cameraOffset = this.cameraControlInput.getCameraOffset();
    this.mainCamera.position.x = mapLinear(cameraOffset, 0, 1, -0.2, 0.2);
    this.mainCamera.rotation.y = mapLinear(cameraOffset, 0, 1, -0.08, 0.08);

    this.smoke && this.smoke.update(time);

    if (this.snow) {
      const intensity = this.snowControlInput.getIntensity() + 1;
      this.snow.update(time, intensity);
    }

    if (this.isDebugViewEnabled && this.cameraHelper) {
      this.cameraHelper.update();
    }

    this.renderer.render(
      this.scene,
      this.isDebugViewEnabled ? this.debugCamera : this.mainCamera
    );
  }

  initControls(camera, eventTarget) {
    const controls = new OrbitControls(camera, eventTarget);
    controls.enabled = false;

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    document.body.addEventListener('mousedown', ev => {
      if (!ev.shiftKey) {
        return;
      }

      if (!controls.enabled) {
        return;
      }

      mouse.x = (ev.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(ev.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObject(this.scene, true);

      if (intersects.length > 0) {
        controls.target.copy(intersects[0].point);
      }
    });

    return controls;
  }
}
