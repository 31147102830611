import {Math as Math2} from 'three';

const AVG_FRAMES = 15;

export default class CameraControlInput {
  constructor() {
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleDeviceOrientation = this.handleDeviceOrientation.bind(this);

    this.useOrientation = false;
    this.containerWidth = window.innerWidth;
    this.averageOffset = 0;
    this.lastValue = 0;

    window.addEventListener('deviceorientation', this.handleDeviceOrientation);
    window.addEventListener('resize', this.handleResize);
    document.body.addEventListener('mousemove', this.handleMouseMove);
  }

  update() {
    this.averageOffset =
      ((AVG_FRAMES - 1) * this.averageOffset) / AVG_FRAMES +
      this.lastValue / AVG_FRAMES;
  }

  getCameraOffset() {
    return this.averageOffset;
  }

  handleDeviceOrientation(ev) {
    // when first receiving an orientation-event, we know that DO is supported
    // and disable mouse-control
    if (!this.useOrientation) {
      this.useOrientation = true;

      window.removeEventListener('resize', this.handleResize);
      document.body.removeEventListener('mousemove', this.handleMouseMove);
    }

    this.lastValue = Math2.mapLinear(ev.gamma, -45, 45, 1, -1);
  }

  handleMouseMove(ev) {
    const w = this.containerWidth;

    this.lastValue = Math2.mapLinear(ev.clientX, 0, w, -1, 1);
  }

  handleResize(ev) {
    this.containerWidth = window.innerWidth;
  }
}
