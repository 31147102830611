import * as THREE from 'three';

export default class Stars extends THREE.Points {
  constructor() {
    const numPoints = 2000;
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(numPoints * 3);

    geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));

    const v3 = new THREE.Vector3();
    const spherical = new THREE.Spherical();

    for (var i = 0; i < numPoints; i++) {
      const r = 20 + Math.random() * 10;
      const u = Math.random();
      const v = Math.random();
      spherical.set(r, u * 2 * Math.PI, Math.acos(2 * v - 1));

      v3.setFromSpherical(spherical);
      v3.toArray(positions, i * 3);
    }

    super(
      geometry,
      new THREE.PointsMaterial({
        size: 0.2,
        transparent: true,
        map: Stars.createTexture()
      })
    );
  }

  static createTexture() {
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = 64;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, 64, 64);
    ctx.fillStyle = 'white';
    ctx.arc(32, 32, 24, 0, 2 * Math.PI);
    ctx.fill();

    return new THREE.CanvasTexture(canvas);
  }
}
