import React from 'react';

export default function UbiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 389.91 129.07">
      <path d="M210.14,38.11h9.2V56a12.48,12.48,0,0,1,3.59-2.11,15.73,15.73,0,0,1,6.39-1.17,16,16,0,0,1,7.05,1.48,16.24,16.24,0,0,1,5.26,4,16.88,16.88,0,0,1,3.31,5.92,23.18,23.18,0,0,1,1.13,7.25,20.82,20.82,0,0,1-1.44,7.76,18.67,18.67,0,0,1-4.13,6.31A20.1,20.1,0,0,1,234,89.77a24.59,24.59,0,0,1-9.06,1.6,79,79,0,0,1-14.8-1.25Zm9.2,44.51a13.41,13.41,0,0,0,2.26.59,24.88,24.88,0,0,0,3.66.2,11.67,11.67,0,0,0,4.91-1,11.17,11.17,0,0,0,3.59-2.61A11,11,0,0,0,236,76a13.46,13.46,0,0,0,.74-4.4,16.31,16.31,0,0,0-.51-4.06,10.35,10.35,0,0,0-1.6-3.47,8,8,0,0,0-2.77-2.42,8.29,8.29,0,0,0-4-.9c-2.81,0-4.92.71-6.35,2.14a8,8,0,0,0-2.14,5.89Z" />
      <path d="M320.14,38.11h9.2V56a12.48,12.48,0,0,1,3.59-2.11,15.73,15.73,0,0,1,6.39-1.17,16,16,0,0,1,7.05,1.48,16.24,16.24,0,0,1,5.26,4,16.88,16.88,0,0,1,3.31,5.92,23.18,23.18,0,0,1,1.13,7.25,20.82,20.82,0,0,1-1.44,7.76,18.67,18.67,0,0,1-4.13,6.31A20.1,20.1,0,0,1,344,89.77a24.59,24.59,0,0,1-9.06,1.6,78.87,78.87,0,0,1-14.79-1.27Zm9.2,44.51a13.41,13.41,0,0,0,2.26.59,24.88,24.88,0,0,0,3.66.2,11.67,11.67,0,0,0,4.91-1,11.17,11.17,0,0,0,3.59-2.61A10.94,10.94,0,0,0,346,76a13.46,13.46,0,0,0,.74-4.4,16.31,16.31,0,0,0-.51-4.06,10.35,10.35,0,0,0-1.6-3.47,8,8,0,0,0-2.77-2.42,8.29,8.29,0,0,0-4-.9c-2.81,0-4.92.71-6.35,2.14a8,8,0,0,0-2.14,5.89Z" />
      <path d="M161.25,38.11h9.82v31.1a22.35,22.35,0,0,0,.58,5.34,10.57,10.57,0,0,0,1.91,4,8.46,8.46,0,0,0,3.51,2.57,16.66,16.66,0,0,0,10.76,0,8.53,8.53,0,0,0,3.51-2.57,10.57,10.57,0,0,0,1.91-4,22.35,22.35,0,0,0,.58-5.34V38.11h9.82V71a22.93,22.93,0,0,1-1.41,8.11,18.33,18.33,0,0,1-4.13,6.51A19.61,19.61,0,0,1,191.45,90a26.15,26.15,0,0,1-18,0,19.61,19.61,0,0,1-6.66-4.37,18.33,18.33,0,0,1-4.13-6.51,22.92,22.92,0,0,1-1.4-8.11Z" />
      <path d="M251.05,38.11h9.2v9.2h-9.2Zm0,15.43h9.2V90.1h-9.2Z" />
      <path d="M266.72,38.11h9.2v52h-9.2Z" />
      <path d="M304.84,86.67a12.56,12.56,0,0,1-4.52,3.2,16.13,16.13,0,0,1-6.78,1.4,14.82,14.82,0,0,1-4.48-.7A12.71,12.71,0,0,1,285,88.43a10.66,10.66,0,0,1-3-3.7,11.71,11.71,0,0,1-1.13-5.3,12.87,12.87,0,0,1,1.17-5.57,11.56,11.56,0,0,1,3.27-4.13,14.47,14.47,0,0,1,5.07-2.53,23,23,0,0,1,6.55-.86h2.42c.89,0,1.74,0,2.57.12l2.88.16v-.86a5,5,0,0,0-2.18-4.29,9.58,9.58,0,0,0-5.69-1.56,15.79,15.79,0,0,0-6.24,1,28.07,28.07,0,0,0-4.13,2.18l-4.21-6.23c.73-.52,1.56-1.06,2.49-1.6a18.94,18.94,0,0,1,3.28-1.48,26.79,26.79,0,0,1,4.32-1.09,33.28,33.28,0,0,1,5.73-.43,19.31,19.31,0,0,1,6.35,1,14.48,14.48,0,0,1,4.91,2.7,11.79,11.79,0,0,1,3.12,4.21,12.89,12.89,0,0,1,1.09,5.34V90.07h-8.57Zm0-13.09-2.73-.16c-.73-.06-1.49-.1-2.3-.12h-2.06q-4.44,0-6.24,1.75a5.43,5.43,0,0,0-1.79,4,4.41,4.41,0,0,0,1.68,3.66,7.9,7.9,0,0,0,5,1.32,9.52,9.52,0,0,0,5.85-2,8.29,8.29,0,0,0,1.91-2.18,5.17,5.17,0,0,0,.74-2.65Z" />
      <path d="M363.89,79.65a13.89,13.89,0,0,0,4.33,2.84,14.86,14.86,0,0,0,6,1.13,12.2,12.2,0,0,0,4.75-.78,2.66,2.66,0,0,0,1.79-2.57,2.87,2.87,0,0,0-1.64-2.73,14.42,14.42,0,0,0-2.81-1.25A48,48,0,0,0,371.48,75a35.39,35.39,0,0,1-3.9-1.25,14.86,14.86,0,0,1-3.78-2.14,11.55,11.55,0,0,1-2.88-3.27,8.87,8.87,0,0,1-1.13-4.56,9.06,9.06,0,0,1,1.32-4.87,11.55,11.55,0,0,1,3.47-3.59,16.82,16.82,0,0,1,4.83-2.22,19.15,19.15,0,0,1,5.42-.78,28.9,28.9,0,0,1,8.22,1,19.49,19.49,0,0,1,6.12,3l-4.76,6.47a15.47,15.47,0,0,0-3.9-2,16.22,16.22,0,0,0-5.69-.9c-2.18,0-3.7.33-4.56,1A2.88,2.88,0,0,0,369,63.23c0,1.14.71,2,2.14,2.69A40.76,40.76,0,0,0,377.77,68a29.26,29.26,0,0,1,5,1.56,14,14,0,0,1,3.82,2.34,9.56,9.56,0,0,1,2.46,3.31,11.11,11.11,0,0,1,.86,4.56,9.37,9.37,0,0,1-1.25,4.79,11.41,11.41,0,0,1-3.39,3.63,17.08,17.08,0,0,1-5,2.3,21.72,21.72,0,0,1-6,.82A28.14,28.14,0,0,1,365,89.86a15.53,15.53,0,0,1-6.63-4.25Z" />
      <path d="M64.26.55a64.26,64.26,0,1,0,64.26,64.26A64.26,64.26,0,0,0,64.26.55ZM43.14,83.06A18.26,18.26,0,1,1,61.4,64.8,18.27,18.27,0,0,1,43.14,83.06Zm32.3,0h-7V46.49h7Zm14,0h-7V46.49h7Zm14,0h-7V46.49h7Z" />
      <path d="M43.14,53.47A11.33,11.33,0,1,0,54.47,64.8h0A11.33,11.33,0,0,0,43.14,53.47Z" />
    </svg>
  );
}
