import {Vector3, Vector2} from 'three';

const DECAY = 0.97;
const MAX_INTENSITY = 20;
const PIXEL_FACTOR = 20;

let max = 0;

export default class SnowControlInput {
  constructor() {
    const a = new Vector3();

    window.addEventListener('devicemotion', ev => {
      let intensity = a.copy(ev.acceleration).length();
      intensity = Math.min(MAX_INTENSITY, intensity);
      max = Math.max(max, intensity);
    });

    let lastMousePosition = new Vector2();
    let firstRun = true;

    window.addEventListener('mousemove', ev => {
      const position = new Vector2(ev.clientX, ev.clientY);

      if (firstRun) {
        lastMousePosition.copy(position);
        firstRun = false;
        return;
      }

      const distance = position.distanceTo(lastMousePosition);
      const intensity = Math.min(distance / PIXEL_FACTOR, MAX_INTENSITY);

      max = Math.max(max, intensity);

      lastMousePosition.copy(position);
    });
  }

  getIntensity() {
    max *= DECAY;
    return max;
  }
}
