import * as THREE from 'three';
import {Particle, ParticleEmitter} from './particles';

const v3 = new THREE.Vector3();

class SmokeParticle extends Particle {
  init() {
    this.obj = new THREE.Mesh(
      new THREE.IcosahedronGeometry(1.5 + Math.random() * 2, 0),
      new THREE.MeshStandardMaterial({
        color: 0xcccccc,
        metalness: 0,
        roughness: 0.8,
        transparent: true,
        flatShading: true,
        depthWrite: false,
        opacity: 1
      })
    );

    this.obj.castShadow = this.obj.receiveShadow = true;
  }

  spawn() {
    this.obj.position.set(0, 0, 0);
    this.obj.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, 0);
    this.velocity = new THREE.Vector3(
      (Math.random() - 0.5) / 600,
      (0.5 + Math.random() * 0.4) / 200,
      (Math.random() - 0.5) / 600
    );
    this.context.parent.add(this.obj);
  }

  update(t, dt) {
    const progress = (t - this.spawntime) / this.lifetime;
    const scale = 0.7 + Math.pow(progress, 2) * 0.5;
    // const scale = 0.6 + Math.pow(progress, 60);
    // const opacity = 1 - Math.pow(progress, 100);

    this.obj.scale.set(scale, scale, scale);
    // this.obj.material.opacity = opacity;
    this.obj.position.add(v3.copy(this.velocity).multiplyScalar(dt));
  }

  die() {
    this.context.parent.remove(this.obj);
  }
}

export default class Smoke extends THREE.Group {
  constructor() {
    super();

    this.particleEmitter = new ParticleEmitter({
      particleTypes: [SmokeParticle],
      particleContext: {parent: this},
      maxParticles: 15,
      minLifetime: 3500,
      maxLifetime: 4500
    });
  }

  update(time) {
    this.particleEmitter.update(time);
  }
}
