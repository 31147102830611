import {isIOS} from './detect-envs';

/**
 * Generates href to AR model for android
 */
function getAndroidHref() {
  const protocol = 'https';
  const modelUrl = '//xmas2019.ubilabs.net//assets/xmas_final-android.glb';
  const title = 'Winter wonderland in AR by Ubilabs';

  const backLink = encodeURIComponent(window.location.href);
  return `intent:${modelUrl}?link=${backLink}&title=${title}#Intent;scheme=${protocol};package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${backLink}%23model-viewer-no-ar-fallback;end;`;
}

export function getARLink() {
  return isIOS() ? '/assets/xmas_final-ios.usdz' : getAndroidHref();
}
