import React from 'react';

import InfoIcon from '../icons/info';

import './info.css';

/**
 * Button to launch AR mode
 */
export default function InfoScreenButton(props) {
  const {onClick} = props;
  return (
    <button className="info-screen-button" onClick={onClick}>
      <InfoIcon />
      <span>Check out in AR</span>
    </button>
  );
}
