import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

const X = new THREE.Vector3(1, 0, 0);
const Y = new THREE.Vector3(0, 1, 0);
const DEACTIVATION_TIMEOUT = 2000;

export default class ExploreModeControls {
  constructor(target, camera, domElement) {
    this.target = target;
    this.camera = camera;
    this.domElement = domElement;
    this.enabled = true;
    this.active = false;
    this.dragging = false;
    this.lastPos = new THREE.Vector2();
    this.rotationDelta = new THREE.Vector2();
    this.deactivationTimer = null;

    this.cameraPositionTween = new TWEEN.Tween(this.camera.position).easing(
      TWEEN.Easing.Quintic.InOut
    );
    this.rotationTween = new TWEEN.Tween(this.target.quaternion).easing(
      TWEEN.Easing.Cubic.InOut
    );
    this.initialQuaternion = this.target.quaternion.clone();

    this.bindEvents();
  }

  activate() {
    this.active = true;
    this.cameraPositionTween.to({z: 6, y: -1}, 1000).start();
  }

  deactivate() {
    this.active = false;
    this.cameraPositionTween.to({x: 0, y: 0, z: 0}, 2000).start();
    this.rotationTween.to(this.initialQuaternion, 1200).start();
  }

  restartDeactivateTimer() {
    if (this.deactivationTimer) {
      clearTimeout(this.deactivationTimer);
    }

    this.deactivationTimer = setTimeout(() => {
      this.deactivate();
    }, DEACTIVATION_TIMEOUT);
  }

  update() {
    if (!this.enabled) {
      return;
    }

    this.rotationDelta.multiplyScalar(0.8);
    if (Math.abs(this.rotationDelta.x) > 1e-6) {
      this.target.rotateOnWorldAxis(X, this.rotationDelta.x);
    } else {
      this.rotationDelta.x = 0;
    }
    if (Math.abs(this.rotationDelta.y) > 1e-6) {
      this.target.rotateOnAxis(Y, this.rotationDelta.y);
    } else {
      this.rotationDelta.y = 0;
    }
  }

  startDrag(x, y) {
    if (!this.enabled || !this.camera) {
      return;
    }

    if (!this.active) {
      this.activate();
    }

    clearTimeout(this.deactivationTimer);
    this.dragging = true;

    this.lastPos.set(x, y);
  }

  endDrag() {
    this.dragging = false;
    this.restartDeactivateTimer();
  }

  dragMove(x, y) {
    if (!this.dragging) {
      return;
    }

    this.rotationDelta.y += (x - this.lastPos.x) / 500;
    this.rotationDelta.x += (y - this.lastPos.y) / 500;

    this.lastPos.set(x, y);
  }

  bindEvents() {
    this.domElement.addEventListener('mousedown', ev => {
      this.startDrag(ev.pageX, ev.pageY);
    });
    this.domElement.addEventListener('touchstart', ev => {
      this.startDrag(ev.touches[0].pageX, ev.touches[0].pageY);
    });

    this.domElement.addEventListener('mousemove', ev => {
      this.dragMove(ev.pageX, ev.pageY);
    });
    this.domElement.addEventListener('touchmove', ev => {
      this.dragMove(ev.touches[0].pageX, ev.touches[0].pageY);
    });

    this.domElement.addEventListener('mouseup', () => {
      this.endDrag();
    });
    this.domElement.addEventListener('touchend', () => {
      this.endDrag();
    });
  }
}
