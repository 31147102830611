import React from 'react';
import {SizeMe} from 'react-sizeme';

import GreetingCard from './greeting-card/greeting-card';
import InfoScreen from './info-screen/info-screen';

import ThreeJsWrapper from './scene/ThreeJsWrapper';

import {isInternetExplorer11, doesntSupportWebGl} from '../lib/detect-envs';

export default class App extends React.Component {
  /**
   * The initial state
   */
  state = {
    isGreetingShown: true,
    isUnsupportedEnv: false
  };

  componentDidMount() {
    const isUnsupportedEnv = isInternetExplorer11() || doesntSupportWebGl();
    this.setState({isUnsupportedEnv});
  }

  /**
   * Shows info screen
   */
  showInfoScreen = () => {
    this.setState({isGreetingShown: false});
  };

  /**
   * Closes info screen
   */
  closeInfoScreen = () => {
    this.setState({isGreetingShown: true});
  };

  render() {
    const {isUnsupportedEnv, isGreetingShown} = this.state;

    return (
      <div className="App">
        {isUnsupportedEnv ? (
          <div className="scene--fallback" />
        ) : (
          <SizeMe monitorHeight={true}>
            {({size}) => (
              <div className="scene">
                <ThreeJsWrapper width={size.width} height={size.height} />
              </div>
            )}
          </SizeMe>
        )}

        {isUnsupportedEnv && (
          <h2 className="unsupported-message">
            Your browser environment is not supported, nevertheless:
          </h2>
        )}
        {isGreetingShown && (
          <GreetingCard showInfoScreen={this.showInfoScreen} />
        )}
        {!isGreetingShown && (
          <InfoScreen closeInfoScreen={this.closeInfoScreen} />
        )}
      </div>
    );
  }
}
